<template>
  <form class="reward-container" @submit.prevent="onSubmit">

    <RewardGemsEarnedLayout
      :gems="gems"
      :subDescription="$tc('rewards.modals.gems_earned.sub_description')"
      :subTitle="$tc('rewards.modals.gems_earned.sub_title')"
      :description="description"
    />

    <ButtonV2
      class="mt-s24"
      data-cy="button-submit"
      data-testid="button-submit"
      :label="buttonLabel"
      :loading="isLoading"
      size="medium"
      type="submit"
      wide
    />

    <ButtonV2
      v-if="hasNextAction"
      @onClick="onNextTarget"
      class="mt-s16"
      data-cy="button-next-action"
      data-testid="button-next-action"
      :label="nextActionLabel"
      :loading="isLoading"
      size="medium"
      version="secondary"
      wide
    />

  </form>
</template>

<script>
import { mapActions } from 'vuex';
import ButtonV2 from '@/stories/misc/ButtonV2';
import RewardGemsEarnedLayout from '@/modules/rewards/modals/RewardGemsEarned/layout.vue';

import { formatDecimals } from '@/utils/formatters';

export default {
  name: 'RewardGemsEarned',
  components: {
    ButtonV2,
    RewardGemsEarnedLayout
  },
  props: {
    pendingNotifications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      isLoading: false,
    };
  },
  async mounted() {
    await this.acknowledgeUserRewardNotification(this.id);
  },
  computed: {
    buttonLabel() {
      return !this.isLastNotification ? this.$tc('navigation.next') : this.$tc('rewards.modals.gems_earned.earn_more_gems');
    },

    currentNotification() {
      return this.pendingNotifications[this.currentIndex];
    },

    description() {
      return this.lodashGet(this.currentNotification, 'success_message', '');
    },

    gems() {
      return `+${formatDecimals(this.lodashGet(this.currentNotification, 'gems_amount', '0'))}`;
    },

    hasNextAction() {
      return !!this.nextActionLabel && !!this.nextActionTarget;
    },

    id() {
      return this.lodashGet(this.currentNotification, 'id', '');
    },

    isLastNotification() {
      return this.currentIndex === this.pendingNotifications.length - 1;
    },

    nextActionLabel() {
      return this.lodashGet(this.currentNotification, 'action.trigger.next_action_label');
    },

    nextActionTarget() {
      return this.lodashGet(this.currentNotification, 'action.trigger.next_action_target');
    },
  },
  methods: {
    ...mapActions('rewards', ['acknowledgeUserRewardNotification']),

    async onNextTarget() {
      try {
        this.isLoading = true;

        this.hideModal();

        this.goToInternalPageGlobal(this.nextActionTarget);
      } catch (err) {
        this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        if (!this.isLastNotification) {
          ++this.currentIndex;

          return await this.acknowledgeUserRewardNotification(this.id);
        }

        this.hideModal();
        this.goToInternalPageGlobal('/rewards');
      } catch (err) {
        this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.reward-container {
  @apply flex flex-col mb-s20;
  max-width: 408px;
}
</style>
